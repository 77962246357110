import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Divider, Input, SimpleGrid, Stack } from '@chakra-ui/react'
import axios from 'axios';
import { Flex, Box, Text, Card, ScrollView, HStack, Button } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa"; // import an icon of your choice
import debounce from 'lodash/debounce';
import StepperCostum from './Stepper';
import { AiFillCheckCircle, AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import { FoldableCard } from './FoldableCard.js';

// Get the current timestamp in seconds
const timestamp = Math.floor(Date.now() / 1000).toString();

// Generate a random string or unique identifier
const randomString = Math.floor(Math.random() * 1000000).toString();

// Concatenate the timestamp and random string
const tokenString = timestamp + randomString;

console.log(tokenString)

export default function SearchPlace() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();


  const fetchData = async () => {

    axios.post('https://avisgoo.com/api_mobile/localrankchecker/autocomplete',
      //'http://127.0.0.1:8000/localrankchecker/autocomplete',
      { query: query, session_token: tokenString })
      .then(response => setResults(response.data["predictions"]));
  };

  const debouncedFetchData = debounce(fetchData, 1000);

  const handleSelect = (place_id) => {
    navigate('/query', { state: { place_id: place_id, session_token: tokenString } });

  };

  return (
    <Stack spacing={3} px={{ base: 4, md: 10 }} py={{ base: 6, md: 10 }}>
      <StepperCostum activeStep={0}></StepperCostum>

      <Text
        fontSize={{ base: '2xl', md: '4xl' }}
        fontFamily="'Roboto', sans-serif"
        fontWeight="bold"
        letterSpacing="wide"
        color="gray.800"
        mt="8"
      >👀 Trouvez votre entreprise</Text>

      <HStack>
        <Input
          style={{
            borderRadius: 42,
            borderWidth: 2,
            borderColor: "#6200EE",
            shadowRadius: 0,
            backgroundColor: "#EBEBEB",
            borderStyle: "solid",
          }}
          type="text"
          placeholder="Entrer le nom de votre entreprise"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fontSize="md"
          px="4"
          py="2"
          mr="4"
          _focus={{
            borderColor: "#6200EE",
            boxShadow: "outline",
          }}
        />

        <Button
          onClick={() => fetchData()}
          colorScheme="blue"
          bg="#6200EE"
          borderRadius="42"
          cursor="pointer"
          _hover={{
            bg: "blue.700",
          }} >
          Chercher
        </Button>
      </HStack>
      <FoldableCard title="Comment trouver le nom de votre entreprise sur Google ?" >
        <p>Le nom de votre entreprise est le titre qui s'affiche dans votre profil d'entreprise Google. C'est ainsi que les clients le voient lorsqu'ils consultent votre profil sur la recherche Google.</p>
      </FoldableCard>
      <Box>
        {results.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 3, md: 6 }}>
            {results.map((result) => (
              <SearchResultCard key={result.place_id} result={result} onClick={() => handleSelect(result.place_id)} />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Stack>
  );
}


function SearchResultCard({ result, onClick }) {
  return (
    <Card padding={5} onClick={onClick}
      shadow="md"
      borderRadius={42}
      _hover={{ shadow: "lg" }}>
      <Flex align="center">
        <Box as={FaMapMarkerAlt} boxSize={6} mr={4} />
        <Box>
          <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">{result.name}</Text>
          <Text fontSize={{ base: 'sm', md: 'md' }}>{result.adress}</Text>
        </Box>
      </Flex>
    </Card>
  );
}
