
import { React, useState, useEffect } from 'react';
import { Divider } from '@chakra-ui/react'
import { Flex, Box, Text, Card, ScrollView, HStack, Button } from "@chakra-ui/react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";


export const FoldableCard = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Box
      borderWidth="1px"
      borderRadius="42"
      p="8"
      mt={"8"}
      shadow="md"
      _hover={{ shadow: "lg" }}
      w={{ base: "100%", md: "100%" }}
      alignSelf={"center"}
      color="#FAFAFA"
    >
      <Flex justify="space-between" align="center" mb="4">
        <Text fontWeight="bold" fontSize="xl" color="gray.700">
          {title}
        </Text>
        <Button
          size="sm"
          variant="outline"
          colorScheme="blue"
          onClick={handleToggle}
          borderWidth={0}
        >

          {isOpen ? <AiOutlineDown size={28} />
            : <AiOutlineUp size={28} />}
        </Button>
      </Flex>

      {isOpen && (<>

        <Divider />
        <br/>

        <Box >
          <Text fontSize="lg" color="gray.500">
            {children}
          </Text>
        </Box></>
      )}
    </Box>
  );
};
