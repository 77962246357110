import { React, useState, useEffect } from 'react';
import { VStack, HStack, Box } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

export const KeywordComponent = ({ onClose }) => {
  return (
    <Box>

      <Text>{`\t1. N'utilisez pas le nom de votre entreprise.`}</Text>
      <Text >{`2. Utilisez des expressions que vos clients sont susceptibles de taper dans Google lorsqu'ils recherchent vos services.`}</Text>
      <Text >{`\t3. Utilisez le nom de votre ville ou région à la place de "près de moi"..`}</Text>
      <br/>
      <HStack>
        <VStack alignItems={"left"}>
          <HStack >
            <AiFillCheckCircle color='green' size={28} />
            <Text >
              Montréal
            </Text>
          </HStack>

          <HStack>

            <AiFillCheckCircle color='green' size={28} />
            <Text>
              Courtier Immobilier

            </Text>
          </HStack>


          <HStack mb={2}>
            <AiFillCheckCircle color='green' size={28} />
            <Text >
              Salon de beauté à Québec

            </Text>
          </HStack>
        </VStack>
        
        <VStack alignItems={"left"}>
          <HStack >
            <BsFillExclamationTriangleFill color='red' size={28} />
            <Text >
              Dentiste à proximité

            </Text>
          </HStack>



          <HStack>
            <BsFillExclamationTriangleFill color='red' size={28} />
            <Text >
              Plomberie d'Alain

            </Text>
          </HStack>

          <HStack>
            <BsFillExclamationTriangleFill color='red' size={28} />
            <Text >
              Ou manger du sushi?

            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box >
  );
};