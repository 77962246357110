import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Flex, Input, Button, Box, Spacer, VStack, HStack } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import StepperCostum from './Stepper';
import { FoldableCard } from './FoldableCard.js';
import { KeywordComponent } from './KeywordComponent.js'




export default function SearchPlace() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showKeywords, setShowKeywords] = useState(true);

  const navigate = useNavigate();

  const location = useLocation();


  const { place_id, session_token } = location.state;

  useEffect(() => {
  });

  const handleSubmit = () => {
    navigate('/map', { state: { place_id: place_id, query: query, session_token: session_token } });

  };

  return (
    <>
      <Box m={8}>

      <StepperCostum activeStep={1}></StepperCostum>

      </Box>
      <Flex direction={{ base: "column", md: "column" }} m={5}
      >

        <Text fontSize={['xl', '2xl', '4xl']} mb={4}>✒️ Décrire ce que vos clients cherchent</Text>
        <HStack >
          <Input
            type="text"
            placeholder="Mot clés de recherche"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={{
              borderRadius: 42,
              borderWidth: 2,
              borderColor: "#6200EE",
              shadowRadius: 0,
              backgroundColor: "#EBEBEB",
              borderStyle: "solid",
            }}
            fontSize="md"
            px="4"
            py="2"
            mr="4"
            _focus={{
              borderColor: "#6200EE",
              boxShadow: "outline",
            }}
          />
          <Button onClick={() => handleSubmit()} colorScheme="blue"
          paddingLeft={10}
          paddingRight={10}
            textOverflow={"ellipsis"}
            overflow="hidden"
            bg="#6200EE"
            borderRadius="42"
            cursor="pointer"
            _hover={{
              bg: "blue.700",
            }}   >
            Voir ma position
          </Button>
        </HStack>

        <FoldableCard title="Décrivez les mots clés de recherche clients" >
          <KeywordComponent onClose={() => setShowKeywords(false)}></KeywordComponent>
        </FoldableCard>

      </Flex>
    </>
  );
}

