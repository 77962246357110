import React, { useRef, useEffect, useState, useCallback } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Badge, Box, Progress, Flex, SimpleGrid, Text, VStack, Image, Stack, useMediaQuery, position } from "@chakra-ui/react";
import {
  Heading,
  Center,
} from "@chakra-ui/react";
import './Map.css';
import ReactStars from 'react-stars'
import ReactLoading from 'react-loading';
import { extendTheme } from "@chakra-ui/react"

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react'

const customTheme = extendTheme({
  colors: {
    low: "#b72e2e",
    med: "#FF9514",
    high: "#0E8345",
  }
})

const colorScheme = (position) => {
  if (position <= 10) return "#0E8345";
  if (position > 15) return "#b72e2e";
  if (position > 10 && position <= 15) return "#FF9514";

  return "#266EF1"
};

const colorScheme2 = (position) => {
  if (position <= 10) return "green";
  if (position > 15) return "red";
  if (position > 10 && position <= 15) return "orange";

  return "#266EF1"
};

const visibilty = (position) => {
  if (position <= 10) return "HIGH";
  if (position > 15) return "LOW";
  if (position > 10 && position <= 15) return "MEDIUM";

  return "#266EF1"
};


function Map() {
  const mapRef = useRef(null);
  const location = useLocation();

  const { place_id, query, session_token } = location.state;
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [markers, setMarkers] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [locationInit, setLocationInit] = useState({ lat: 35.02, long: -7.5 });


  useEffect(() => {
    console.log("fetch data");
    try {
      axios.post(
        'https://avisgoo.com/api_mobile/localrankchecker/checkRank',
        //'http://127.0.0.1:8000/localrankchecker/checkRank',
        {
          "query": query,
          "place_id": place_id,
          "session_token": session_token
        }
      ).then(response => {
        let markers = [];

        for (let key in response.data) {
          if (key === "0") {
            const clientMarker = Object.keys(response.data[key]["data"]).find(prop => response.data[key]["data"][prop]["type"] === "client");
            markers.push({ "position": Number(response.data[key]["data"]["client"].position) + 1, "location": response.data[key]["location"] });
          } else {
            markers.push({ "position": response.data[key].position, "location": response.data[key]["location"] });
          }
        }
        setMarkers(markers);
        setLocationInit({ lat: markers[0].location.lat, long: markers[0].location.long })
        setData(response.data["0"]["data"]);
        setIsLoaded(true);

      });

    } catch (error) {
      console.error(error);
    }

  }, []);

  useEffect(() => {
    console.log("draw data");

    const map = L.map(mapRef.current, {
      center: [locationInit.lat, locationInit.long],
      zoom: 14,
      attributionControl: false,
    });

    // Add a tile layer to the map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(map);

    const addCircleMarker = (elt) => {
      const circle = L.circle([elt.location.lat, elt.location.long], {
        radius: 500,
        fillColor: colorScheme(elt.position),
        fillOpacity: 1,
        color: 'white',
        weight: 4,
      }).addTo(map);

      const text = L.divIcon({
        html: '<div class="circle-label text-center" style="color: white; font-size: 20px; ">' + elt.position + '</div>',
        className: 'circle-label-container',
        iconSize: [20, 20],
        iconAnchor: [12, 12],
      });



      L.marker([elt.location.lat, elt.location.long], { icon: text }).addTo(map);

      map.on('zoomend', () => {
        const zoomLevel = map.getZoom();
        const radius = zoomLevel >= 14 ? 500 : 500 * (14 - zoomLevel);
        const fontSize = zoomLevel >= 14 ? 20 : 20 * (14 - zoomLevel);
        console.log(radius);

        circle.setRadius(radius);
        text.options.html = '<div class="circle-label text-center" style="color: white; font-size: ' + fontSize + 'px; ">' + elt.position + '</div>';
      });

    };


    markers.forEach((elt) => {
      addCircleMarker(elt);
    });

    return () => {
      map.remove();
    };
  }, [markers, selectedMarker]);

  const handleSelectCompetitor = (elt) => {
    markers[0].position = Number(elt.position) + 1;

    setMarkers(markers);
    setSelectedMarker(elt.place_id);
  }



  return <>

    <Flex direction={{ base: "column", md: "row" }}>
      <Box w={{ base: "100%", md: "100%" }} h={{ base: "500px", md: "720px" }} style={{ border: "1px solid black" }} ref={mapRef}></Box>
      <Box w={{ base: "100%", md: "50%" }} h={{ base: "500px", md: "720px" }} p={4}>


        {!isLoaded ? (
          <div>

            <ReactLoading type={"spin"} color="blue" />
            <Box fontWeight="regular" paddingBottom={2} >Fetching data ...</Box>
          </div>
        ) : (
          <MarkerList query={query} data={data} handleSelectCompetitor={handleSelectCompetitor} />
        )}
      </Box>

    </Flex>
  </>;
}




function BasicUsage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} colorScheme='blue'>Commencez  </Button>

      <Modal isOpen={isOpen} onClose={onClose}   >
        <ModalOverlay    />
        <ModalContent>
          
          <ModalHeader></ModalHeader>
          
          <ModalCloseButton />
          <ModalBody>

            <style jsx>
              {`
              .pipedriveWebForms {
                width:100%;
                height: 720px;
              }

              .pipedriveWebForms iframe {
                width: 100%;
                height: 100%;
              }
            `}
            </style>
            <div className="pipedriveWebForms" >
              <iframe
                src={`https://webforms.pipedrive.com/f/6WfGrr2AA6tIcitXXbVMsLiacEiZFshAjtDmhq9Yl7Buhr9DKbeBqAzcNshuGRW54D`}
                name={`form`}
                scrolling="no"
                seamless="seamless"
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


function MarkerList({ query, data, handleSelectCompetitor }) {
  const client = data.client




  if (data) {

    return (<>
      <Box
        width={{ base: "100%", md: "100%" }}
        marginBottom={{ base: 8, md: 0 }}
      >

<Box
          p="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom={4}
        >
          <Text fontSize="m" fontWeight="bold" mb="2">
          Découvrez comment améliorez votre classement Google

          </Text>
          {
          BasicUsage()
        }
        </Box>
          
        <Box
          p="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"

          marginBottom={4}
        >
          <Text fontSize="m" fontWeight="bold" mb="2">
            Votre mots-clés de recherche
          </Text>
          <Text>
            <Badge colorScheme={"blue"}>{query}</Badge>
          </Text>
        </Box>
        

        <Box
          p="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom={4}
        >
          <Badge colorScheme={colorScheme2(client.position)}>
            Pos. {Number(client.position) + 1}
          </Badge>
          <Box mt="2">
            <Box fontWeight="bold" fontSize={16} paddingBottom={2}>
              {client.name}
            </Box>
            <Box fontWeight="regular" fontSize={12} paddingBottom={2}>
              {client.formatted_address}
            </Box>

            <div style={{ display: "flex", alignItems: "center" }}>
              <ReactStars
                count={5}
                value={client.rating}
                edit={false} // disable user interaction
                size={16} // set size of stars
                activeColor="#ffd700" // set color of filled stars
                color="#cccccc"
              />
              <Box fontWeight="regular"> ({client.user_ratings_total})</Box>
            </div>
            <br />
            <Box
              w={250}>
              <Progress
                value={
                  client.position >= 21
                    ? 1
                    : data.competitors.length - client.position
                }
                max={data.competitors.length}
                colorScheme={colorScheme2(client.position)}
                borderRadius="full"
              />
            </Box>
            <Box
              fontWeight="bold"
              fontSize={12}
              textColor={colorScheme(client.position)}
            >
              {visibilty(client.position)}
            </Box>
          </Box>
        </Box>
      </Box>


      <Stack
        spacing={{ base: 4, md: 8 }}
      >

        <Heading as="h3" size="md" mb={2}  >
          Competitors ( {data.competitors.length} )
        </Heading>
        <Box
          borderRadius="md"
          boxShadow="sm"
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <SimpleGrid columns={1} spacing={1} minChildWidth="100%"
            flexDirection="row"
          >

            {data.competitors.map((elt) => (
              <Box key={elt.place_id} p="4" borderWidth="1px" borderRadius="md" boxShadow="sm" onClick={() => handleSelectCompetitor(elt)}
              >
                <Badge colorScheme={colorScheme2(elt.position)}>Pos. {Number(elt.position) + 1}</Badge>
                <Box mt="2">
                  <Box fontWeight="bold" fontSize={16} paddingBottom={2} >{elt.name}</Box>
                  <Box fontWeight="regular" paddingBottom={2} fontSize={12}>{elt.formatted_address}</Box>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ReactStars
                      count={5}
                      value={elt.rating}
                      edit={false} // disable user interaction
                      size={16} // set size of stars
                      activeColor="#ffd700" // set color of filled stars
                      color="#cccccc" />
                    <Box fontWeight="regular"   >   ({elt.user_ratings_total})</Box>

                  </div>
                  <br />

                  <Box
                    w={250}
                    h={5}
                  >
                    <Progress borderRadius="full" value={(elt.position >= 21) ? 1 : data.competitors.length - elt.position} max={data.competitors.length} colorScheme={colorScheme2(elt.position)} />
                  </Box>
                  <Box fontWeight="bold" fontSize={12} textColor={colorScheme(elt.position)}>{visibilty(elt.position)}</Box>

                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Stack>

    </>

    );
  } else {
    return null;
  }
}
export default Map;
