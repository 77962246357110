import React, { useState } from "react";
import { Grid } from '@chakra-ui/react';

import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Box,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react'
import Column from "antd/es/table/Column";

function StepperCostum({ activeStep }) {

  const steps = [
    { title: 'Trouvez votre entreprise' },
    { title: 'Inserer votre phrase' },
    { title: 'Votre visibilté local' },
  ]

  return (
<Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))" gap={1}>
  <Stepper index={activeStep}>
    {steps.map((step, index) => (
      <Step key={index}>
        <Box >
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
        </Box>

        <Box>
          <StepTitle>{step.title}</StepTitle>
        </Box>

        <Box>
          <StepSeparator />
        </Box>
      </Step>
    ))}
  </Stepper>
</Grid>
  );
}

export default StepperCostum;
