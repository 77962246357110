import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom'
import { Box } from "@chakra-ui/react";

import React, { useEffect, useState } from 'react';
import SearchPlace from './SearchPlace'
import Map from './Map'
import InputQuery from './InputQuery'
import Stepper from './Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepperCostum from './Stepper';

function App() {



  const Pages = () => {
    let routes = useRoutes([
      { path: "", element: <SearchPlace /> },
      { path: "/query", element: <InputQuery /> },
      { path: "/map", element: <Map /> },
    ]);
    return routes;
  };

  const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
  ];
  

  return (
    <ChakraProvider theme={theme}>
      
      <Box  w="100%" h="100%">
        <Router>

          <Pages />
        </Router>
      </Box>

    </ChakraProvider>
  );
}

export default App;

